body { display:block; }
@media only screen and (orientation:portrait){
   #cont_escalable {  
         -webkit-transform: rotate(90deg);
         -moz-transform: rotate(90deg);
         -o-transform: rotate(90deg);
         -ms-transform: rotate(90deg);
         transform: rotate(90deg);
        }

}
@media only screen and (orientation:landscape){
   body {  
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
   }
}


*{
    margin: 0;
    margin: 0;

    font-family: 'Lobster',cursive;
    font-size: 1.1rem;
}

body{
    background: #fe8c00;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #f83600, #fe8c00);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #f83600, #fe8c00); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.main{
    width: 100vw;
    height: 100vh;
    
    display: flex;
}