.container--form{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form{
    background-color: rgb(245, 168, 67);
    width: 400px;
    height: 425px;
    box-sizing: border-box; 
    border-radius: 25px;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form--label{
    display: block;
    border-bottom: 1px solid black;
    margin: 10px 0;
    width: 100%;
}

.form--selectInput{
    width: 100%;
    border-radius: 25px;
    padding: 5px 10px; 
    background-color: white;
    margin-bottom: 10px;
}

.form--textInput{
    display: block;
    box-sizing: border-box;

    width: 100%;
    border-radius: 25px;
    border: 1px solid black;
    margin-bottom: 10px;
}
 
.form--submitInput{
    width: 50%;
    border-radius: 25px;
    /* box-sizing: border-box; */
    padding: 5px;

    margin: 10px 0;
}