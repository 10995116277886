.container--sales{
    box-sizing: border-box;
    width: 50%;
    height: 100%;

    padding: 30px 20px;

    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
}